<template>
  <div class="portfolio">
    <div class="main-carousel">
      <div class="carousel-container">
        <b-carousel
        id="carousel-1"
        v-model="slide"
          :interval="4000"
          controls
          indicators
          background="#ababab"
          img-width="1024"
          img-height="480"
          style="text-shadow: 1px 1px 2px #333;"
          >
          <b-carousel-slide v-for="(item, index) in imgs" :key="index"
          :img-src="item"
          ></b-carousel-slide>
        </b-carousel>

      </div>

      <h2>林口 · 蔣宅</h2>
      <div class="arrows">
        <router-link :to="{name: 'Portfolio1'}" class="arrow arrow-left">
          <svg class="d-none" xmlns="http://www.w3.org/2000/svg" width="133" height="18" viewBox="0 0 133 18" fill="none">
            <path d="M25 1L2.5 17.5H132.5" stroke="black"/>
          </svg>
          <svg class="" xmlns="http://www.w3.org/2000/svg" width="39" height="18" viewBox="0 0 39 18" fill="none">
            <path d="M25 1L2.5 17.5H38.5" stroke="black"/>
          </svg>
          <p>林口 · 顏宅</p>
        </router-link>
        <router-link :to="{name: 'Portfolio3'}" class="arrow arrow-right">
          <svg class="d-none" xmlns="http://www.w3.org/2000/svg" width="132" height="18" viewBox="0 0 132 18" fill="none">
            <path d="M107.5 0.999998L130 17.5L-1.44248e-06 17.5" stroke="black"/>
          </svg>
          <svg class="" xmlns="http://www.w3.org/2000/svg" width="40" height="18" viewBox="0 0 40 18" fill="none">
            <path d="M15.5 0.999998L38 17.5L0.499999 17.5" stroke="black"/>
          </svg>
          <p>新莊 · 永鼎方宅</p>
        </router-link>
      </div>
    </div>
    <div class="footer">
      <DFCDFooter></DFCDFooter>
    </div>
  </div>
</template>

<script>
import DFCDFooter from '@/components/DFCDFooter.vue';

import p1 from '@/assets/images/p02/1.png';
import p2 from '@/assets/images/p02/2.png';
import p3 from '@/assets/images/p02/3.png';
import p4 from '@/assets/images/p02/4.png';
import p5 from '@/assets/images/p02/5.png';
import p6 from '@/assets/images/p02/6.png';

export default {
  name: 'Portfolio2',
  components: {
    DFCDFooter,
  },
  data() {
    return {
      imgs: [p1, p2, p3, p4, p5, p6]
    }
  },
  props: {
  },
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
  }

}
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/basic";

  .portfolio {
    width: 100%;
    // height: 100vh;
    // height: 100dvh;
    // display: flex;
    // flex-direction: column;

    .main-carousel {
      padding-top: 6rem;
      h2 {
        font-size: 1rem;
        margin: 0;
        padding: 1.5rem 2rem;
        text-align: center;
      }

      ::v-deep .carousel-item {
        width: 100%;
        height: calc(100vh - 6rem);
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      ::v-deep .carousel-indicators{
        li {
          background-color: transparent;
          border: solid 1px #fff;
          width: .875rem;
          height: .875rem;
          border-radius: 50%;
          margin-left: .4rem;
          margin-right: .4rem;
          opacity: .9;
          &.active {
            background-color: #fff;
            opacity: .9;
          }
        }
      }

      .arrows {
        display: flex;
        justify-content: space-between;
        padding: 1.8rem 1.5rem 0;
        .arrow {
          &:hover {
            text-decoration: none;
          }
          &.arrow-left {
            text-align: left;
          }
          &.arrow-right {
            text-align: right;
          }
          p {
            margin: 0;
            margin-top: .8em;
            padding-top: .2rem;
            color: $color-link-black;
          }
        }
        
      }

      @include smaller-than-medium {
        h2 {
          padding: 1rem 1.5rem;

        }

        
        .arrows {
          padding: 1rem .5rem 0;
        }
        
      }
    }

    .footer {
      flex: 0 0 auto;
    }
  }

</style>

